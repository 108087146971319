import React, { useState } from 'react';

function AnnotatorMain(){
    return (
        <div>
            <h3>This is a palce holder for annotators</h3>
        </div>
    );

}

export default AnnotatorMain;